import * as React from "react"
import { useCookies } from "react-cookie"

import * as styles from './cookie-consent.module.css'

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["consent"]);

  const acceptConsent = () => { setCookie('consent', true) };
  const rejectConsent = () => { setCookie('consent', false) };

  return (
    <div className={`column is-3 ${styles.wrapper} ${cookies['consent'] === undefined ? '' : 'is-hidden'}`}>
      <div className={`p-6 ${styles.box}`}>
        <h4 className="mb-2 is-size-4 has-text-weight-bold display-font">Cookie Policy</h4>
        <p className="mb-4 is-size-6 has-text-grey-dark">
          We use cookies to recognise visitors. We also use them to analyse our traffic and measure ad campaign effectiveness. If you agree to our use of cookies, please continue to use our website.
        </p>
        <a className="button is-outlined is-link mr-2" href="#" onClick={acceptConsent}>ACCEPT</a>
        <a className="button is-outlined is-danger" href="#" onClick={rejectConsent}>REJECT</a>
      </div>
    </div>
  )
}

export default CookieConsent
