
import * as React from "react"
import { graphql, HeadFC, PageProps, HeadProps, } from "gatsby"

import Layout from "../components/layout"
import Navbar from "../components/navbar"
import Header from "../components/header"
import About from "../components/about"
import Links from "../components/links"
import Services from "../components/services"
import Projects from "../components/projects"
import Testimonials from "../components/testimonials"
import Contact from "../components/contact"
import Logo from "../components/logo"
import Footer from "../components/footer"

import Seo from "../components/seo"

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
        description
        tel
        telEmergency
        email
        address
        postcode
        logoSmallImg
        logoLargeImg
        copyright1
        copyright2
        about {
          title
          subtitle
          content
          profileImg
        }
        links {
          link
          badgeImg
        }
        services {
          backgroundImg
          title
          subtitle
          data {
            title
            description
            iconImg
          }
        }
        projects {
          title
          data {
            thumbnailImg
            fullImg
          }
        }
        testimonials {
          title
          data {
            quote
            person
          }
        }
        contact {
          title
          subtitle
        }
      }
    }
    allFile(
      filter: {sourceInstanceName: {eq: "images"}}
    ) {
      images: edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`

const Index = ({ data: { site: { siteMetadata }, allFile: { images } } }: PageProps<Queries.IndexQuery>) => {
  const resolveImg = (filename: string): string => {
    return images.find((image: any) => image.node.relativePath == filename)?.node.publicURL
  }

  return (
    <Layout>
      <Navbar
        logoImg={resolveImg(siteMetadata.logoSmallImg)}
        tel={siteMetadata.tel}
      />
      <Header
        title={siteMetadata.title}
        tel={siteMetadata.tel}
        email={siteMetadata.email}
      />
      <About
        title={siteMetadata.about.title}
        subtitle={siteMetadata.about.subtitle}
        content={siteMetadata.about.content}
        profileImg={resolveImg('profile.jpg')}
      />
      <Links
        data={siteMetadata.links.map((d: any) => { return { ...d, badgeImg: resolveImg(d.badgeImg) } })}
      />
      <Services
        title={siteMetadata.services.title}
        subtitle={siteMetadata.services.subtitle}
        backgroundImg={resolveImg(siteMetadata.services.backgroundImg)}
        data={siteMetadata.services.data.map((d: any) => { return { ...d, iconImg: resolveImg(d.iconImg) } })}
      />
      <Projects
        title={siteMetadata.projects.title}
        data={siteMetadata.projects.data.map((d1: any) => { return d1.map((d2: any) => { return { ...d2, thumbnailImg: resolveImg(d2.thumbnailImg), fullImg: resolveImg(d2.fullImg) } }) })}
      />
      <Testimonials
        title={siteMetadata.testimonials.title}
        data={siteMetadata.testimonials.data} />
      <Contact
        title={siteMetadata.contact.title}
        subtitle={siteMetadata.contact.subtitle}
        tel={siteMetadata.tel}
        telEmergency={siteMetadata.telEmergency}
        email={siteMetadata.email}
        address={siteMetadata.address}
        postcode={siteMetadata.postcode}
      />
      <Logo
        logoImg={resolveImg(siteMetadata.logoLargeImg)}
      />
      <Footer
        copyright1={siteMetadata.copyright1}
        copyright2={siteMetadata.copyright2}
      />
    </Layout>
  )
}

export const Head: HeadFC = ({ data }: HeadProps<Queries.IndexQuery>) => (
  <Seo title={data.site.siteMetadata.description} />
)

export default Index
