import * as React from "react"

interface ProjectsProps {
  title: string
  data: [[{
    thumbnailImg: string,
    fullImg: string,
  }]],
}

const Projects = ({ title, data, }: ProjectsProps) => {
  const [modalIsActive, setModalIsActive] = React.useState(false)
  const [modalImgSrc, setModalImgSrc] = React.useState('')

  const openModal = (src: string) => {
    setModalImgSrc(src)
    setModalIsActive(true)
  }
  const closeModal = () => {
    setModalIsActive(false)
  }

  return (
    <>
      <section className="section" id="projects">
        <div className="section-heading my-6 has-text-centered display-font">
          <h3 className="title is-2">{title}</h3>
        </div>
        <div className="container">
          <div className="tile is-ancestor">
            {data.map(d1 => {
              return (
                <div className="tile is-parent is-vertical is-4">
                  {d1.map(d2 => (
                    <figure className="image tile is-child is-flex-grow-0 is-clickable">
                      <img className="js-modal-trigger" src={d2.thumbnailImg} loading="lazy" onClick={() => openModal(d2.fullImg)} />
                    </figure>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <div id="modal-gallery-img" className={`modal ${modalIsActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-content modal-content-img" onClick={closeModal}>
          <figure className="image is-clickable">
            <img id="modal-gallery-img-img" src={modalImgSrc} loading="lazy" />
          </figure>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
      </div>
    </>
  )
}

export default Projects
