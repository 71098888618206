import * as React from "react"

interface ContactProps {
  title: string,
  subtitle: string,
  tel: string,
  telEmergency: string,
  email: string,
  address: string,
  postcode: string,
}

const Contact = ({ title, subtitle, tel, telEmergency, email, address, postcode, }: ContactProps) => (
  <section className="section" id="contact">
    <div className="section-heading my-6 has-text-centered display-font">
      <h3 className="title is-2">{title}</h3>
      <p className="subtitle is-3">{subtitle}</p>
    </div>
    <div className="container py-6">
      <div className="columns">
        <div className="column is-3 has-text-centered">
          <h5 className="has-text-grey is-size-4">Phone</h5>
          <p className="is-size-3">
            <a href={`tel:${tel.replaceAll(' ', '')}`}>
              {tel}
            </a>
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <h5 className="has-text-grey is-size-4">Emergency</h5>
          <p className="is-size-3">
            <a href={`tel:${telEmergency.replaceAll(' ', '')}`}>
              {telEmergency}
            </a>
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <h5 className="has-text-grey is-size-4">Email</h5>
          <p className="is-size-3">
            <a href={`mailto:${email}`}>
              {email}
            </a>
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <h5 className="has-text-grey is-size-4">In Person</h5>
          <p className="is-size-3">
            <a href={`https://maps.google.com/maps?q=${postcode.replaceAll(' ', '+')}`} target="_blank">
              {address}
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Contact
