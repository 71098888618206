import * as React from "react"

interface ServicesProps {
  backgroundImg: string,
  title: string,
  subtitle: string,
  data: [{
    iconImg: string,
    title: string,
    description: string,
  }],
}

const Services = ({ backgroundImg, title, subtitle, data }: ServicesProps) => (
  <section className="section" id="services">
    <img src={backgroundImg} className="inline-bg-image" />
    <div className="section-heading my-6 has-text-centered display-font">
      <h3 className="title is-2">{title}</h3>
      <p className="subtitle is-3">{subtitle}</p>
    </div>
    <div className="container">
      <div className="columns is-multiline">
        {data.map(d => (
          <div className="column is-3">
            <div className="box p-6 has-text-centered">
              <div className="content">
                <figure className="image is-96x96 mx-auto"><img src={d.iconImg} /></figure>
                <h4 className="title is-4 my-6">{d.title}</h4>
                <p className="is-size-4">{d.description}</p>
              </div>
            </div>
          </div>
        )
        )}
      </div>
    </div>
  </section>
)

export default Services
