import * as React from "react"

interface AboutProps {
  title: string,
  subtitle: string,
  content: string[],
  profileImg: string,
}

const About = ({ title, subtitle, content, profileImg, }: AboutProps) => (
  <section className="section" id="about">
    <div className="section-heading my-6 has-text-centered display-font">
      <h3 className="title is-2">{title}</h3>
      <p className="subtitle is-3">{subtitle}</p>
    </div>
    <div className="container">
      <div className="columns is-centered is-variable is-8">
        <div className="column is-5">
          <div className="content is-size-4">
            {content.map(c => (
              <p>
                {c}
              </p>
            ))}
          </div>
        </div>
        <div className="column is-narrow">
          <figure className="image m-auto" id="figure-profile">
            <img className="is-rounded" src={profileImg} />
          </figure>
        </div>
      </div>
    </div>
  </section>
)

export default About
