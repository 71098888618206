import * as React from "react"
import { Link } from "gatsby"

// import logo from "../images/logo-s.png"

interface NavbarProps {
  logoImg: string,
  tel: string,
}

const Navbar = ({ logoImg, tel }: NavbarProps) => (
  <nav className="navbar is-fixed-top is-spaced">
    <div className="navbar-brand">
      <a className="navbar-item" href="#">
        <img src={logoImg} width="166" height="28" />
      </a>
      <div className="navbar-burger" data-target="navbarExampleTransparentExample">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div id="navbarExampleTransparentExample" className="navbar-menu">
      <div className="navbar-end">
        <a className="navbar-item is-size-5" href={`tel:${tel.replaceAll(' ', '')}`}>
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-phone"></i>
            </span>
            <span>{tel}</span>
          </span>
        </a>
        <a className="navbar-item is-size-5" href="#about">
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-info"></i>
            </span>
            <span>About</span>
          </span>
        </a>
        <a className="navbar-item is-size-5" href="#services">
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-bars"></i>
            </span>
            <span>Our Services</span>
          </span>
        </a>
        <a className="navbar-item is-size-5" href="#projects">
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-th-list"></i>
            </span>
            <span>Previous Projects</span>
          </span>
        </a>
        <div className="navbar-item">
          <div className="buttons">
            <a href="#contact" className="button is-link">
              <strong>CONTACT US</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
)

export default Navbar
