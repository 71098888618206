import * as React from "react"

interface LogoProps {
  logoImg: string,
}

const Logo = ({ logoImg }: LogoProps) => (
  <section className="section">
    <div className="container has-text-centered my-6">
      <img src={logoImg} />
    </div>
  </section>
)

export default Logo
