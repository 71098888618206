import * as React from "react"

interface LinksProps {
  data: [{
    link: string,
    badgeImg: string,
  }],
}

const Links = ({ data }: LinksProps) => (
  <section className="section my-6">
    {/* Desktop */}
    <div className="container is-hidden-touch">
      <div className="level">
        {data.map(d => (
          <div className="level-item">
            <a href={d.link} target='_blank'>
              <img src={d.badgeImg} width={150} />
            </a>
          </div>
        ))}
      </div>
    </div>
    {/* Touch */}
    <div className="container is-hidden-desktop">
      <div className="has-text-centered">
        {data.map(d => (
          <a href={d.link}>
            <img src={d.badgeImg} width={100} />
          </a>
        ))}
      </div>
    </div>
  </section>
)

export default Links
