import * as React from "react"
import { Link } from "gatsby"

interface TestimonialsProps {
  title: string,
  data: [{
    quote: string,
    person: string,
  }],
}

const Testimonials = ({ title, data, }: TestimonialsProps) => (
  <section className="section slanted-bg py-6" id="testimonials">
    <div className="section-heading my-6 has-text-centered display-font">
      <h3 className="title is-2">{title}</h3>
    </div>
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-half is-size-4">
          {data.map(d => (
            <div className="content mb-6">
              <p className="is-italic">
                “{d.quote}”
              </p>
              <p className="has-text-weight-bold">
                {d.person}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Testimonials
