import * as React from "react"
import { Link } from "gatsby"

interface HeaderProps {
  title: string,
  tel: string,
  email: string,
}

const Header = ({ title, tel, email, }: HeaderProps) => (
  <section className="hero is-large is-link has-background-image display-font">
    <div className="hero-body">
      <div className="container">
        {/* Desktop */}
        <div className="is-hidden-touch mx-6">
          <h1 className="title is-1 is-spaced">
            {title}
          </h1>
          <h2 className="subtitle is-2">
            <a href={`tel:${tel.replaceAll(' ', '')}`}>{tel}</a>
            <br />
            <a href={`mailto:${email}`}>{email}</a>
          </h2>
        </div>
        {/* Touch */}
        <div className="is-hidden-desktop my-6">
          <h1 className="title is-2 is-spaced">
            {title}
          </h1>
          <h2 className="subtitle is-4">
            <a href={`tel:${tel.replaceAll(' ', '')}`}>{tel}</a>
            <br />
            <a href={`mailto:${email}`}>{email}</a>
          </h2>
        </div>
      </div>
    </div>
  </section>
)

export default Header
