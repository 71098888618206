import * as React from "react"

interface FooterProps {
  copyright1: string,
  copyright2: string,
}

const Footer = ({ copyright1, copyright2, }: FooterProps) => (
  <footer className="footer has-background-grey-dark has-text-grey-light">
    <div className="section-heading has-text-centered">
      <p>
        {copyright1}
      </p>
      <p>
        {copyright2}
      </p>
      <p>
        Designed & built by <a href="https://web4biz.co.uk" className="has-text-link-light" target='_blank'>web4biz.co.uk</a>
      </p>
    </div>
  </footer>
)

export default Footer
